<template>
    <div>
        <PageTitle title="康宁云医院" height="8rem"></PageTitle>
        <div style="position: relative;top: -3rem;width: 85%;margin: 0 auto;">
            <List :width="100">
                <ListItem>
                    <template #left>
                        <img style="width: 45px;" :src="$store.getters.userInfo.AvatarUrl || require('../assets/personal.png')" />
                    </template>
                    <template #right>
                        <h4>{{ $store.getters.userInfo.NickName }}，您好！</h4>
                        <p>请按照提示进行操作</p>
                    </template>
                </ListItem>

                <ListItem>
                    <template v-slot:top>
                        <p>手机号码验证</p>
                        <van-form
                                ref="phoneNumberForm"
                                label-width="50px"
                                colon
                                scroll-to-error>
                            <!-- 输入手机号，调起手机号键盘 -->
                            <van-field
                                    name="phoneNumber"
                                    v-model="formData.phoneNumber"
                                    center
                                    clearable
                                    label="手机号"
                                    placeholder="请输入手机号"
                                    :rules="[{ required: true }, { pattern, message: '请输入正确的手机号码' }]"
                                    >
                                <template #button>
                                    <van-button size="small" type="primary" :disabled="checkNumberBtn" @click="getCheckNumber()">{{ checkNumberText }}</van-button>
                                </template>
                            </van-field>
                            <!-- 允许输入正整数，调起纯数字键盘 -->
                            <van-field
                                    name="checkNumber"
                                    v-model="formData.checkNumber"
                                    type="digit"
                                    label="验证码"
                                    placeholder="请输入验证码"
                                    :rules="[{ required: true }]"
                                    />
                        </van-form>
                    </template>
                </ListItem>
            </List>
            <van-button type="primary" block @click="checkPhoneNumber()">提交</van-button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';

    import { getCheckNumber, checkPhoneNumber, userLogIn } from '@/api/user.js';

    export default {
        name: 'Home',
        components: {
            PageTitle,
            List,
            ListItem
        },
        data(){
            return {
                pattern: /^[1]([3-9])[0-9]{9}$/,
                formData: {
                    phoneNumber: '',
                    checkNumber: ''
                },
                checkNumberBtn: false,
                checkNumberText: '获取验证码'
            }
        },
        methods:{
            getCheckNumber(){
                this.$refs.phoneNumberForm.validate('phoneNumber').then(
                    () => {
                        this.checkNumberBtn = true;
                        let time = 60;
                        let timeCount = setInterval(()=>{
                            time --;
                            this.checkNumberText = time + 's';
                            if(time < 1){
                                this.checkNumberBtn = false;
                                this.checkNumberText = '获取验证码';
                                clearInterval(timeCount);
                            }
                        }, 1000);
                        //获取验证码
                        getCheckNumber(this.formData.phoneNumber).then(()=>{},()=>{});
                    },() => {}
                )
            },
            checkPhoneNumber(){
                this.$refs.phoneNumberForm.validate().then(
                    () => {
                        //提交表单
                        checkPhoneNumber(this.formData.phoneNumber, this.formData.checkNumber).then(
                            () => {
                                userLogIn(this.formData.phoneNumber, this.$store.getters.openId).then(
                                    (result)=>{
                                        localStorage.setItem('access_token', result.token)
                                        localStorage.setItem('access_refresh', result.refresh)
                                        this.$store.dispatch('user/setLogInfo', {
											phoneNumber: this.formData.phoneNumber,
											userId: result.userId
                                        });
                                        this.$router.push('/');
                                    },()=>{});
                            },() => {})
                    },() => {})
            }
        }
    }
</script>

<style scoped="" lang="less">
    .infoContainer{
        padding: 13px 0;

        p{
            margin: 0;
        }

        .top {
            p {
                text-align: center;
            }

            .van-button{
                width: 86px;
            }
        }

        .right {
            h4{
                margin: 0 0 10px;
            }

            p{
                font-size: 14px;
            }
        }
    }
</style>
